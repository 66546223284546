import { VuexModule, Module, Action } from 'vuex-class-modules';
import Axios from 'axios';
import store from '@/store';

@Module({ generateMutationSetters: true })
export class CancellationReasonModule extends VuexModule {
    cancellationReasons: { name: string }[] = [];

    @Action
    async index() {
        const { data } = await Axios.get<{ name: string }[]>(
            'cancellation-reason'
        );
        this.cancellationReasons = data;
    }
}
export const cancellationReasonModule = new CancellationReasonModule({
    store,
    name: 'cancellationReason'
});
