

















































































import { Component, Vue } from 'vue-property-decorator';
import { cancellationReasonModule } from '@/store/modules/cancellation-reason';
import { appointmentModule } from '@/store/modules/appointment';
@Component
export default class CancelAppointment extends Vue {
    reason = '';
    other = '';
    step = 1;

    get options() {
        return cancellationReasonModule.cancellationReasons;
    }

    mounted() {
        cancellationReasonModule.index();
    }

    async store() {
        try {
            await appointmentModule.cancelAppointment({
                idCita: this.$route.params.id,
                estado: 3,
                comentario: this.other || this.reason
            });
            this.step++;
        } catch (error) {
            this.$notify.error({
                title: 'Error',
                message:
                    typeof error.mensaje === 'string'
                        ? error.mensaje
                        : 'Hemos tenido algunos inconvenientes'
            });
        }
    }
}
